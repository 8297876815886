import { keysOf } from '@unbounded/unbounded-components'
import type { Backend } from '~api'
import { ExchangeBackend, MiPasaBackend, PapillonBackend, UnboundedBackend } from '~api'
import { CURRENT_PLATFORM_TYPE, Platform, initializeExchangeBackend, initializeUnboundedBackend, platformMeta } from './platform'

const platformBackends: Record<Platform, Backend> = {
  [Platform.mipasa]: new MiPasaBackend('/api/mipasa'),
  [Platform.papillon]: new PapillonBackend('/api'),
  [Platform.crypto]: new MiPasaBackend('/api/mipasa'),
}

// Assign each platform a corresponding Backend
keysOf(Platform).forEach(platform => {
  platformMeta[platform].backend = platformBackends[platform]
})

// Set unboundedBackend variable
initializeUnboundedBackend(new UnboundedBackend('/api/unbounded'))

// Set exchangeBackend variable
if (CURRENT_PLATFORM_TYPE === Platform.crypto) {
  initializeExchangeBackend(new ExchangeBackend(platformBackends[Platform.crypto]))
}
