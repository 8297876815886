import { FileTypes } from '~types'

export const UPLOAD_MAX_FILE_SIZE_MB = 100
export const UPLOAD_MAX_FILE_SIZE = UPLOAD_MAX_FILE_SIZE_MB * 1024 * 1024

export const UPLOAD_THUMBNAIL_MAX_SIZE_MB = 5

export const UPLOAD_MIME_TYPES: Record<FileTypes, string> = {
  [FileTypes.CSV]: 'text/csv',
  [FileTypes.JSON]: 'application/json',
  [FileTypes.JPEG]: 'image/jpeg',
  [FileTypes.JPG]: 'image/jpeg',
  [FileTypes.PNG]: 'image/png',
  [FileTypes.IPYNB]: 'application/x-ipynb+json',
  [FileTypes.Any]: '*',
}

export const UPLOAD_MIME_EXTENSIONS: Record<FileTypes, string[]> = {
  [FileTypes.CSV]: ['.csv'],
  [FileTypes.JSON]: ['.json'],
  [FileTypes.JPEG]: ['.jpeg', '.jpg'],
  [FileTypes.JPG]: ['.jpeg', '.jpg'],
  [FileTypes.PNG]: ['.png'],
  [FileTypes.IPYNB]: ['.ipynb'],
  [FileTypes.Any]: ['*'],
}

export const UPLOAD_DEFAULT_ACCEPTED_FILES = {
  [UPLOAD_MIME_TYPES.csv]: UPLOAD_MIME_EXTENSIONS.csv,
  [UPLOAD_MIME_TYPES.json]: UPLOAD_MIME_EXTENSIONS.json,
}

export const UPLOAD_ANY_ACCEPTED_FILES = {
  [UPLOAD_MIME_TYPES['*']]: UPLOAD_MIME_EXTENSIONS['*'],
}

export const UPLOAD_THUMBNAIL_ACCEPTED_FILES = [FileTypes.PNG, FileTypes.JPEG]
