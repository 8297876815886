export const autodetectMimeType = (filename: string, defaultType = '') => {
  // note: image preview will NOT work if MIME type is not correct here
  const extensionSep = filename.lastIndexOf('.')

  if (extensionSep === -1) {
    return defaultType
  }

  const extension = filename.substring(extensionSep + 1).toLowerCase()

  switch (extension) {
    case 'svg':
      return 'image/svg+xml'
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'gif':
      return 'image/gif'
    case 'webp':
      return 'image/webp'
    case 'png':
      return 'image/png'
    case 'apng':
      return 'image/apng'
    case 'avif':
      return 'image/avif'
    case 'pdf':
      return 'application/pdf'
    default:
      return defaultType
  }
}
