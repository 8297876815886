export const sleep = (time: number) =>
  new Promise(resolve => {
    setTimeout(resolve, time)
  })

export default async function waitUntil(fn: () => boolean, timeoutMs = 6000, iterationDelayMs = 300): Promise<void> {
  const startTime = new Date().getTime()
  const endTime = startTime + timeoutMs

  while (new Date().getTime() <= endTime && !fn()) {
    await sleep(iterationDelayMs)
  }

  if (new Date().getTime() > endTime) {
    throw new Error('Timeout when waiting for something to happen.')
  }
}
